export default [
  {
    text: "Мои данные",
    link: { name: "LigaForumSecondShiftProfile" },
    key: "profile",
    disabled: false,
  },
  {
    text: "Выбор трека и команды",
    link: { name: "LigaForumSecondShiftTracks" },
    key: "tracks",
    disabled: false,
  },
  {
    text: "Информация",
    link: { name: "LigaForumSecondShiftInfo" },
    key: "info",
    disabled: false,
  },
];
